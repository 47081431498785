/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap-table@1.19.1/dist/bootstrap-table.min.js
 * - /npm/bootstrap-table@1.19.1/dist/locale/bootstrap-table-es-ES.min.js
 * - /npm/bootstrap-table@1.19.1/dist/extensions/export/bootstrap-table-export.min.js
 * - /npm/bootstrap-table@1.19.1/dist/extensions/filter-control/bootstrap-table-filter-control.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
